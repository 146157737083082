<template>
  <v-card
    v-if="items.length > 0"
    class="pa-5"
  >
    <div
      class="mb-3 d-flex align-center"
    >
      <h3
        class="mr-auto text-left d-flex align-center justify-space-between"
      >
        Payments Due & Alerts
      </h3>

      <v-badge
        v-if="error.length > 0 || warning.length > 0 || info.length > 0"
        :color="error.length > 0 ? 'error' : warning.length > 0 ? 'warning' : 'info'"
        :content="error.length + warning.length + info.length"
        inline
      ></v-badge>
    </div>

    <v-alert
      v-for="(item, i) in items"

      :key="'alert' + i"
      style="width: 100%; cursor: pointer;"
      class="alert-box mb-1 pa-2 d-flex flex-row justify-space-between"
      :color="item.alert"
      :type="item.alert"
      @click="handleAlert(item)"
    >
      <div
        v-if="item.alert === 'error' || item.alert === 'warning'"
      >
        <span>
          {{ item.name }} - Click to Pay!
        </span>

        <span v-if="width > 600">&nbsp;|&nbsp;</span>
        <br v-else>

        <span>{{ item.lname }} &lt;{{ item.line1 }}&gt;</span>

        <span v-if="width > 600">&nbsp;|&nbsp;</span>
        <br v-else>

        <span><b>Due: {{ convertDate(item.due_date) }}</b></span>

        <span v-if="width > 600">&nbsp;|&nbsp;</span>
        <br v-else>

        <span>
          ${{ item.amount.toFixed(2) }}
          <span
            v-if="item.alert === 'error' && parseFloat(item.interest) > 1">
              &nbsp;({{ ((parseFloat(item.interest) - 1) * 100).toFixed(2) }}% interest)
          </span>
        </span>

      </div>
      <div
        v-else
      >
        <b>{{ item.topic }}</b>

        <span v-if="width > 600">&nbsp;-&nbsp;</span>
        <br v-else>

        <span>{{ item.description }}</span>
      </div>
    </v-alert>

    <div
      v-if="false"
      class="d-flex align-center justify-center mt-3"
      style="width: 100%;"
    >
      <v-btn
        v-if="items.length > showing"
        @click="showing += 1"
        color="primary"
        outlined
        small
        class="mx-auto"
      >
        Show More
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AlertsPanel',

  props: {
    error: {
      type: Array,
      required: false,
      default: () => { return Array() },
    },

    warning: {
      type: Array,
      required: false,
      default: () => { return Array() },
    },

    info: {
      type: Array,
      required: false,
      default: () => { return Array() },
    },
  },

  data: () => ({
    items: [],
    showing: 1,
    width: 0
  }),

  methods: {
    handleAlert(item) {
      if (item.alert === 'error' || item.alert === 'warning') {
        this.$router.push('/dashboard/' + this.$route.path.match(/member|manager/i)[0] + '/payment?type=' + item.type + '&id=' + item.id + '&property_id=' + item.property_id);
      }
    },

    convertDate(d) {
      const d2 = new Date();
      d2.setFullYear(parseInt(d.slice(0, 4)), parseInt(d.slice(5, 7)) - 1, parseInt(d.slice(8, 10)));
      d2.setHours(0, 0, 0);
      return d2.toDateString().slice(4);
    },

    convertDateTime(d) {
      const d2 = new Date(d);
      d2.setFullYear(parseInt(d.slice(0, 4)), parseInt(d.slice(5, 7)) - 1, parseInt(d.slice(8, 10)));
      d2.setHours(parseInt(d.slice(11, 13)), parseInt(d.slice(14, 16)), parseInt(d.slice(17, 19)));
      return d2.toString().slice(4, 24)
    },
  },

  destroyed() {
    window.removeEventListener('resize', () => {
      this.width = window.innerWidth;
    });
  },

  created() {
    this.width = window.innerWidth;
    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
    });

    this.items = [];
    this.items.push(...this.info.map((item) => {
      return {
        ...item,
        alert: 'info',
      }
    }))
    this.items.push(...this.error.map((item) => {
      return {
        ...item,
        alert: 'error',
      }
    }))
    this.items.push(...this.warning.map((item) => {
      return {
        ...item,
        alert: 'warning',
      }
    }))
  }
};
</script>
<style>

</style>
