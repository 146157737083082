module.exports = {
  alerts: {
    id: {
      label: 'ID',
      type: 'number',
      disabled: {
        edit: true,
      },
      hidden: {
        add: true,
      },
      required: true,
    },
    start_date: {
      label: 'Start Date',
      type: 'date',
      required: true,
    },
    end_date: {
      label: 'End Date',
      type: 'date',
      required: true,
    },
    topic: {
      label: 'Topic',
      type: 'text',
      required: true,
    },
    description: {
      label: 'Description',
      type: 'textarea',
      required: true,
    },
  },

  master: {
    id: {
      label: 'ID',
      type: 'number',
      disabled: {
        edit: true,
      },
      hidden: {
        add: true,
      },
      required: true,
    },
    lot: {
      label: 'Lot #',
      type: 'number',
      required: true,
    },
    member_of_record: {
      label: 'Member of Record',
      type: 'autocomplete',
      itemsEndpoint: '/users/list/members',
      required: true,
    },
    line1: {
      label: 'Address Line 1',
      type: 'text',
      required: true,
    },
    line2: {
      label: 'Address Line 2',
      type: 'text',
      required: false,
    },
    city: {
      label: 'City',
      type: 'select',
      items: [
        { text: 'Deming', value: 'Deming', },
      ],
      defaultValue: 'Deming',
      required: true,
    },
    state: {
      label: 'State/Province',
      type: 'select',
      items: [
        { text: 'Washington', value: 'WA', },
      ],
      defaultValue: 'WA',
      required: true,
    },
    country: {
      label: 'Country',
      type: 'combobox',
      items: [
        'USA',
      ],
      defaultValue: 'USA',
      required: true,
    },
    zip: {
      label: 'Zip',
      type: 'select',
      items: [
        { text: '98244', value: '98244', },
      ],
      defaultValue: '98244',
      required: true,
    },
    discount: {
      label: 'Discount %',
      type: 'number',
      required: true,
      ge0: true,
      le100: true,
      prefill: 0,
    },
    property_url: {
      label: 'Whatcom Property ID',
      type: 'url',
      required: false,
    },
    email: {
      label: 'Email',
      type: 'email',
      required: true,
      email: true,
    },
    email2: {
      label: 'Email #2',
      type: 'email',
      required: false,
      email: true,
      hidden: {
        add: true,
      },
    },
    email3: {
      label: 'Email #3',
      type: 'email',
      required: false,
      email: true,
      hidden: {
        add: true,
      },
    },
    permissions: {
      label: 'Permissions',
      type: 'select',
      items: [
        { text: 'Admin', value: 8 },
        { text: 'Member', value: 4 },
        { text: 'Manager', value: 2 },
      ],
      required: true,
    },
    fname: {
      label: 'First Name',
      type: 'text',
      required: true,
    },
    lname: {
      label: 'Last Name',
      type: 'text',
      required: true,
    },
    phone: {
      label: 'Phone',
      type: 'tel',
      required: false,
      prefix: '+',
      placeholder: '1 (555) 555-5555',
      phone: true,
      hidden: {
        add: true,
      },
    },
    phone2: {
      label: 'Phone #2',
      type: 'tel',
      required: false,
      prefix: '+',
      placeholder: '1 (555) 555-5555',
      phone: true,
      hidden: {
        add: true,
      },
    },
    phone3: {
      label: 'Phone #3',
      type: 'tel',
      required: false,
      prefix: '+',
      placeholder: '1 (555) 555-5555',
      phone: true,
      hidden: {
        add: true,
      },
    },
    mailing_line1: {
      label: 'Mailing Address Line 1',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_line2: {
      label: 'Mailing Address Line 2',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_city: {
      label: 'Mailing City',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_state: {
      label: 'Mailing State/Province',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_country: {
      label: 'Mailing Country',
      type: 'combobox',
      items: [
        'USA', 'CA',
      ],
      defaultValue: 'USA',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_zip: {
      label: 'Mailing Zip',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    license_plates: {
      label: 'License Plates',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      }
    },
    notes: {
      label: 'Property Notes',
      type: 'textarea',
      required: false,
    },
    owner_notes: {
      label: 'Owner Notes',
      type: 'textarea',
      required: false,
    },
    activated: {
      label: 'Activated',
      type: 'boolean',
      hidden: {
        add: true,
      },
    },
    email_official_communications: {
      label: 'Official Comms',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
    email_agm_notice: {
      label: 'AGM Notice',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
    email_paperless_billing: {
      label: 'Paperless Billing',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
    phone_listed: {
      label: 'Phone Listed',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
  },

  violations: {
    id: {
      label: 'ID',
      type: 'number',
      disabled: {
        edit: true,
      },
      hidden: {
        add: true,
      },
      required: true,
    },
    property_id: {
      label: 'Lot #',
      type: 'autocomplete',
      itemsEndpoint: '/properties/list',
      required: true,
    },
    date_of: {
      label: 'Date of Violation',
      type: 'date',
      required: true,
    },
    topic: {
      label: 'Topic',
      type: 'text',
      required: true,
    },
    description: {
      label: 'Description',
      type: 'textarea',
      required: false,
    },
    amount: {
      label: 'Amount',
      type: 'number',
      step: '0.01',
      prefix: '$',
      required: true,
      ge0: true,
    },
    accrue_interest: {
      label: 'Accrue Interest',
      type: 'boolean',
      required: true,
      prefill: true,
    },
    payment_id: {
      label: 'Payment ID',
      type: 'number',
      required: false,
      hidden: {
        add: true,
      },
      disabled: {
        edit: true,
      }
    },
    payment_type: {
      label: 'Type',
      type: 'text',
    },
    payment_date: {
      label: 'Payment Date',
      type: 'datetime-local',
    },
    paypal_confirm: {
      label: 'PayPal Confirm',
      type: 'text',
    },
  },

  users: {
    id: {
      label: 'ID',
      type: 'number',
      disabled: {
        edit: true,
      },
      hidden: {
        add: true,
      },
      required: true,
    },
    email: {
      label: 'Email',
      type: 'email',
      required: true,
      email: true,
    },
    email2: {
      label: 'Email #2',
      type: 'email',
      required: false,
      email: true,
      hidden: {
        add: true,
      },
    },
    email3: {
      label: 'Email #3',
      type: 'email',
      required: false,
      email: true,
      hidden: {
        add: true,
      },
    },
    permissions: {
      label: 'Permissions',
      type: 'select',
      items: [
        { text: 'Admin', value: 8 },
        { text: 'Member', value: 4 },
        { text: 'Manager', value: 2 },
      ],
      required: true,
    },
    fname: {
      label: 'First Name',
      type: 'text',
      required: true,
    },
    lname: {
      label: 'Last Name',
      type: 'text',
      required: true,
    },
    phone: {
      label: 'Phone',
      type: 'tel',
      required: false,
      prefix: '+',
      placeholder: '1 (555) 555-5555',
      phone: true,
      hidden: {
        add: true,
      },
    },
    phone2: {
      label: 'Phone #2',
      type: 'tel',
      required: false,
      prefix: '+',
      placeholder: '1 (555) 555-5555',
      phone: true,
      hidden: {
        add: true,
      },
    },
    phone3: {
      label: 'Phone #3',
      type: 'tel',
      required: false,
      prefix: '+',
      placeholder: '1 (555) 555-5555',
      phone: true,
      hidden: {
        add: true,
      },
    },
    mailing_line1: {
      label: 'Mailing Address Line 1',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_line2: {
      label: 'Mailing Address Line 2',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_city: {
      label: 'Mailing City',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_state: {
      label: 'Mailing State/Province',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_country: {
      label: 'Mailing Country',
      type: 'combobox',
      items: [
        'USA', 'CA',
      ],
      defaultValue: 'USA',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_zip: {
      label: 'Mailing Zip',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    license_plates: {
      label: 'License Plates',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      }
    },
    owner_notes: {
      label: 'Owner Notes',
      type: 'textarea',
      required: false,
    },
    activated: {
      label: 'Activated',
      type: 'boolean',
      hidden: {
        add: true,
      },
    },
    email_official_communications: {
      label: 'Official Comms',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
    email_agm_notice: {
      label: 'AGM Notice',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
    email_paperless_billing: {
      label: 'Paperless Billing',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
    phone_listed: {
      label: 'Phone Listed',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
  },

  globals: {
    id: {
      label: 'ID',
      type: 'number',
      disabled: {
        edit: true,
      },
      hidden: {
        add: true,
        edit: true
      },
    },
    name: {
      label: 'Name',
      type: 'text',
      required: true,
      disabled: {
        edit: true,
      }
    },
    value: {
      label: 'Value',
      type: 'text',
      required: false,
    },
    units: {
      label: 'Units',
      type: 'text',
      required: true,
      disabled: {
        edit: true,
      }
    }
  },

  properties: {
    id: {
      label: 'ID',
      type: 'number',
      disabled: {
        edit: true,
      },
      hidden: {
        add: true,
      },
      required: true,
    },
    lot: {
      label: 'Lot #',
      type: 'number',
      required: true,
    },
    member_of_record: {
      label: 'Member of Record',
      type: 'autocomplete',
      itemsEndpoint: '/users/list/members',
      required: true,
    },
    line1: {
      label: 'Address Line 1',
      type: 'text',
      required: true,
    },
    line2: {
      label: 'Address Line 2',
      type: 'text',
      required: false,
    },
    city: {
      label: 'City',
      type: 'select',
      items: [
        { text: 'Deming', value: 'Deming', },
      ],
      defaultValue: 'Deming',
      required: true,
    },
    state: {
      label: 'State/Province',
      type: 'select',
      items: [
        { text: 'Washington', value: 'WA', },
      ],
      defaultValue: 'WA',
      required: true,
    },
    country: {
      label: 'Country',
      type: 'combobox',
      items: [
        'USA', 'CA',
      ],
      defaultValue: 'USA',
      required: true,
    },
    zip: {
      label: 'Zip',
      type: 'select',
      items: [
        { text: '98244', value: '98244', },
      ],
      defaultValue: '98244',
      required: true,
    },
    discount: {
      label: 'Discount %',
      type: 'number',
      required: true,
      ge0: true,
      le100: true,
      prefill: 0,
    },
    property_url: {
      label: 'Whatcom Property ID',
      type: 'url',
      required: false,
    },
    notes: {
      label: 'Notes',
      type: 'textarea',
      required: false,
    }
  },

  dues: {
    id: {
      label: 'ID',
      type: 'number',
      disabled: {
        edit: true,
      },
      hidden: {
        add: true,
      },
      required: true,
    },
    name: {
      label: 'Name',
      type: 'text',
      required: true,
    },
    amount: {
      label: 'Amount',
      type: 'number',
      step: '0.01',
      prefix: '$',
      required: true,
    },
    due_date: {
      label: 'Due Date',
      type: 'date',
      required: true,
    },
  },

  rentals: {
    id: {
      label: 'ID',
      type: 'number',
      disabled: {
        edit: true,
      },
      hidden: {
        add: true,
      },
      required: true,
    },
    property_id: {
      label: 'Lot #',
      type: 'autocomplete',
      itemsEndpoint: '/properties/list',
      required: true,
      disabled: {
        edit: true,
      }
    },
    check_in: {
      label: 'Check In',
      type: 'date',
      required: true,
    },
    check_out: {
      label: 'Check Out',
      type: 'date',
      required: true,
    },
    amount: {
      label: 'Amount',
      type: 'number',
      step: '0.01',
      prefix: '$',
      required: true,
    },
    due_date: {
      label: 'Due Date',
      type: 'date',
      required: true,
    },
    f_and_f: {
      label: 'Non-Paying',
      type: 'boolean',
      required: true,
      disabled: {
        edit: true,
      }
    },
    guests: {
      label: 'Guests',
      type: 'list',
      template: {
        fname: {
          label: 'First name',
        },
        lname: {
          label: 'Last name',
        }
      },
      min: 1,
      disabled: {
        edit: true,
      }
    },
    vehicles: {
      label: 'Vehicles',
      type: 'list',
      template: {
        make: {
          label: 'Make',
        },
        model: {
          label: 'Model',
        },
        license_plate: {
          label: 'License Plate',
        },
        region: {
          label: 'State/Province',
        }
      },
      min: 1,
      disabled: {
        edit: true,
      }
    },
    payment_id: {
      label: 'Payment ID',
      type: 'number',
      required: false,
      disabled: {
        edit: true,
      }
    },
    payment_type: {
      label: 'Payment Type',
      type: 'select',
      items: [
        {
          text: 'Paypal',
          value: 'PAYPAL',
        },
        {
          text: 'Check',
          value: 'CHECK',
        },
        {
          text: 'Cash',
          value: 'CASH',
        }
      ],
      required: false,
    },
    payment_date: {
      label: 'Payment Date',
      type: 'datetime-local',
      required: false,
    },
    paypal_confirm: {
      label: 'Paypal Confirm',
      type: 'text',
      required: false,
    },
  },

  properties_x_dues: {
    property_id: {
      label: 'Lot #',
      type: 'autocomplete',
      itemsEndpoint: '/properties/list',
      required: true,
      disabled: {
        edit: true,
      }
    },
    due_id: {
      label: 'Due',
      type: 'autocomplete',
      itemsEndpoint: '/dues/list',
      required: true,
      disabled: {
        edit: true,
      }
    },
    payment_id: {
      label: 'Payment ID',
      type: 'number',
      required: false,
      hidden: {
        add: true,
      },
      disabled: {
        edit: true,
      }
    },
    payment_type: {
      label: 'Payment Type',
      type: 'select',
      items: [
        {
          text: 'PAYPAL',
          value: 'PAYPAL',
        },
        {
          text: 'CHECK',
          value: 'CHECK',
        },
        {
          text: 'CASH',
          value: 'CASH',
        }
      ]
    },
    payment_date: {
      label: 'Payment Date',
      type: 'datetime-local',
      required: false,
    },
    paypal_confirm: {
      label: 'Paypal Confirm',
      type: 'text',
      required: false,
    },
  },

  members_owe: {
    user_id: {
      label: 'Member of Record',
      type: 'autocomplete',
      itemsEndpoint: '/users/list/members',
    },
    dues: {
      label: 'Dues',
      type: 'number',
    },
    violations: {
      label: 'Violations',
      type: 'number',
    },
    rentals: {
      label: 'Rentals',
      type: 'number',
    },
  },

  users_x_properties: {
    property_id: {
      label: 'Lot #',
      type: 'autocomplete',
      itemsEndpoint: '/properties/list',
    },
    user_id: {
      label: 'Manager',
      type: 'autocomplete',
      itemsEndpoint: '/users/list/managers',
    },
  },

  payments: {
    id: {
      label: 'ID',
      type: 'number',
      disabled: {
        edit: true,
      },
      hidden: {
        add: true,
      },
    },
    paid_by_name: {
      label: 'Paid Name',
      type: 'string',
    },
    paid_by_email: {
      label: 'Paid Email',
      type: 'email',
    },
    payment_type: {
      label: 'Type',
      type: 'text',
    },
    payment_date: {
      label: 'Payment Date',
      type: 'datetime-local',
    },
    amount: {
      label: 'Amount',
      type: 'number',
    },
    paypal_confirm: {
      label: 'PayPal Confirm',
      type: 'text',
    },
  },
}
