<template>
  <v-app>
    <v-app-bar
      id="AppBar"
      v-if="loggedIn"
      app
      color="primary"
      dark
      class="d-inline"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer=!drawer"
        class="mr-1"
      />

      <div class="d-flex align-center">
        <div class="logo mr-4" style="height: 46px; width: 46px;"></div>
        <h1
          id="MainTitle"
          :style="`color: #FFF`"
        >{{ env.appName }}</h1>
      </div>

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
    >
      <v-list>
        <div
          v-for="(submenu, i) in menu"
          :key="'submenu-' + i"
        >
          <v-list-item-group
            v-if="
              (i==='public' && (permissions & setPerms.PUBLIC)===setPerms.PUBLIC) ||
              (i==='guest' && (permissions & setPerms.GUEST)===setPerms.GUEST) ||
              (i==='manager' && (permissions & setPerms.MANAGER)===setPerms.MANAGER) ||
              (i==='member' && (permissions & setPerms.MEMBER)===setPerms.MEMBER) ||
              (i==='admin' && (permissions & setPerms.ADMIN)===setPerms.ADMIN) ||
              (i==='table_management' && (permissions & setPerms.ADMIN)===setPerms.ADMIN) ||
              (i==='user')
            "
            :key="permissions"
          >
            <v-subheader>{{ i.toUpperCase().replace(/_/gi, ' ') }}</v-subheader>

            <div
              v-for="(item, j) in submenu"
              v-if="item.display"
              :key="'menu-' + j"
            >
              <v-list-item
                v-if="item.to && i.toLowerCase() !== 'user'"
                link
                :to="'/dashboard/' + getRoute(i.toLowerCase()) + '/' + item.to"
              >
                <v-list-item-icon v-if="item.icon"><v-icon v-text="item.icon" color="primary" /></v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-else-if="item.title === 'Logout'"
                @click="logout()"
              >
                <v-list-item-icon v-if="item.icon"><v-icon v-text="item.icon" color="primary" /></v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-else-if="i.toLowerCase() === 'user'"
                link
                :to="'/dashboard/' + item.to"
              >
                <v-list-item-icon v-if="item.icon"><v-icon v-text="item.icon" color="primary" /></v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>

            </div>

          </v-list-item-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view
        :env="env"
        :permissions="permissions"
        :lastRefresh="lastRefresh"
        :userInfo="userInfo"
        :properties="properties"
        :alerts="alerts"
        :rentals="rentals"
        :propList="propList"
        :payments="payments"
        :loading="!loading ? false : true"


        @loggedIn="loggedIn=$event"
        @success="setSuccessMessage($event)"
        @error="setErrorMessage($event)"
        @info="setInfoMessage($event)"
        @permissions="updatePermissions($event)"
        @refresh-userinfo="getUserInfo()"
        @refresh-properties="getProperties()"
        @refresh-alerts="getAlerts()"
        @refresh-rentals="getRentals()"
        @refresh-prop-list="getPropList()"
        @refresh-payments="getPayments()"
        @loading="externalLoad($event)"
      />
    </v-main>

    <v-dialog
      v-model="alertSuccess"
      width="320"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Success
        </v-card-title>

        <v-card-text
          class="mt-2"
        >
          {{ success }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-auto"
            color="success"
            dark
            style="min-width: 120px;"
            @click="postSuccessMessage()"
          >Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="alertError"
      width="320"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Error
        </v-card-title>

        <v-card-text
          class="mt-2"
        >
          {{ error }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-auto"
            color="error"
            dark
            style="min-width: 120px;"
            @click="postErrorMessage()"
          >Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="alertInfo"
      width="320"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Info
        </v-card-title>

        <v-card-text
          class="mt-2"
        >
          {{ info }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-auto"
            color="info"
            dark
            style="min-width: 120px;"
            @click="postInfoMessage()"
          >Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-footer
      class="d-flex flex-column align-center"
    >
      <div class="text-center">&copy;{{ new Date().getFullYear() }} — <strong>{{ env.clubName }}</strong></div>
    </v-footer>

    <!-- Loading -->
    <div
      id="Loading"
      class="loading d-flex flex-column justify-center align-center"
      style="position: fixed; width: 100%; height: 100%; background: #000000CC; color: white;"
    >
      <loader-v2 />

      <h2
        v-if="loading"
        class="loading-words mt-3"
      >{{ loading }}</h2>
    </div>

  </v-app>
</template>

<script>
import { setPerms } from '@/assets/js/constants.js';
import fetchV2 from './assets/js/fetchV2';
import LoaderV2 from '@/components/LoaderV2.vue';

export default {
  name: 'App',

  components: {
    LoaderV2
  },

  data: () => ({
    env: {},
    longTitleWidth: 0,
    lastRefresh: 0,
    userInfo: {},
    properties: [],
    alerts: {},
    rentals: [],
    propList: [],
    payments: [],
    version: '1.02475395',
    loading: false,
    loggedIn: false,
    success: null,
    alertSuccess: false,
    postSuccessCall: null,
    postSuccessPage: null,
    error: null,
    alertError: false,
    postErrorCall: null,
    postErrorPage: null,
    info: null,
    alertInfo: false,
    postInfoCall: null,
    postInfoPage: null,
    drawer: false,
    setPerms: null,
    permissions: 0,
    menu: {
      manager: [
        {
          title: 'My Account',
          icon: 'mdi-account',
          to: 'my-account',
        },
        {
          title: 'My Properties',
          icon: 'mdi-home',
          to: 'properties',
        },
        {
          title: 'My Payments',
          icon: 'mdi-cash-usd',
          to: 'payments',
        },
        {
          title: 'My Rentals',
          icon: 'mdi-home-assistant',
          to: 'rentals',
          rentalsActive: true,
        },
        {
          title: 'Create Rental',
          icon: 'mdi-home-assistant',
          to: 'rental-form',
          rentalsActive: true,
        },
      ],
      member: [
        {
          title: 'My Account',
          icon: 'mdi-account',
          to: 'my-account',
        },
        {
          title: 'My Properties',
          icon: 'mdi-home',
          to: 'properties',
        },
        {
          title: 'My Payments',
          icon: 'mdi-cash-usd',
          to: 'payments',
        },
        {
          title: 'My Rentals',
          icon: 'mdi-home-account',
          to: 'rentals',
          rentalsActive: true,
        },
        {
          title: 'Create Rental',
          icon: 'mdi-home-assistant',
          to: 'rental-form',
          rentalsActive: true,
        },
      ],
      table_management: [
        {
          title: 'Master',
          icon: 'mdi-vector-combine',
          to: 'master',
        },
        {
          title: 'Alerts',
          icon: 'mdi-alert',
          to: 'manage-alerts',
        },
        {
          title: 'Dues',
          icon: 'mdi-account-cash',
          to: 'manage-dues',
        },
        {
          title: 'Dues/Property',
          icon: 'mdi-home-currency-usd',
          to: 'manage-property-dues',
        },
        {
          title: 'Members Owe',
          icon: 'mdi-cash',
          to: 'members-owe',
        },
        {
          title: 'Payments',
          icon: 'mdi-cash',
          to: 'manage-payments',
        },
        {
          title: 'Properties',
          icon: 'mdi-home',
          to: 'manage-properties',
        },
        {
          title: 'Property Managers',
          icon: 'mdi-card-account-details-star',
          to: 'manage-property-managers',
        },
        {
          title: 'Rentals',
          icon: 'mdi-home-account',
          to: 'manage-rentals',
          rentalsActive: true,
        },
        {
          title: 'Users',
          icon: 'mdi-account-group',
          to: 'manage-users',
        },
        {
          title: 'Violations',
          icon: 'mdi-alert-rhombus',
          to: 'manage-violations',
        },
      ],
      admin: [
        {
          title: 'Email Campaign',
          icon: 'mdi-email',
          to: 'email',
        },
        {
          title: 'Resend Activation',
          icon: 'mdi-card-account-mail',
          to: 'resend-activation',
        },
        {
          title: 'Reset Login Attempts',
          icon: 'mdi-lock-reset',
          to: 'reset-login-attempts',
        },
        {
          title: 'Settings',
          icon: 'mdi-earth',
          to: 'settings',
        },
      ],
      user: [
        {
          title: 'Change Password',
          icon: 'mdi-shield-key',
          to: 'change-password',
        },
        {
          title: 'Logout',
          icon: 'mdi-logout',
        }
      ],
    },
    fetches: [],
    externalLoads: 0,
    loadingDelayTime: 500,
    loadingDelay: null,
  }),

  watch: {
    $route(to, from) {
      this.alertSuccess = false;
      this.alertError = false;
      // Reset loading if the route changes
      this.externalLoads = 0;
      this.loading = false;
    },

    loggedIn(val) {
      if (val) {
        this.updateAll();
      }
    },

    alertSuccess(val) {
      if (!val) {
        this.success = null;
      }
    },

    success(val) {
      if (val !== null) {
        this.alertSuccess = true;
      }
    },

    alertError(val) {
      if (!val) {
        this.error = null;
      }
    },

    error(val) {
      if (val !== null) {
        this.alertError = true;
      }
    },

    alertInfo(val) {
      if (!val) {
        this.info = null;
      }
    },

    info(val) {
      if (val !== null) {
        this.alertInfo = true;
      }
    },

    loading(val) {
      console.log('Loading:', val);
      if (val === false) {
        if (document.getElementById('Loading').classList.contains('fade-infront')) {
          document.getElementById('Loading').classList.remove('fade-infront');
        }
      }
      else {
        if (!document.getElementById('Loading').classList.contains('fade-infront') && !this.loadingDelay) {
          this.loadingDelay = setTimeout(() => {
          if (this.loading) {
            document.getElementById('Loading').classList.add('fade-infront');
          }
          else {
            this.resetLoading();
          }
          this.loadingDelay = null;
          }, this.loadingDelayTime);
        }
      }
    },
  },

  computed: {

  },

  methods: {
    setSuccessMessage(msg) {
      if (typeof msg === 'string') {
        this.success = msg;
        this.externalLoad(false);
      }
      else {
        this.success = msg.message;
        this.postSuccessCall = Array(msg.postCall);
        this.postSuccessPage = msg.postPage;
        if (!msg.notExternal) {
          this.externalLoad(false);
        }
      }
    },

    postSuccessMessage() {
      for (const call of this.postSuccessCall ? this.postSuccessCall : []) {
        switch (call) {
          case 'refresh-userinfo':
            this.getUserInfo();
            break;
          case 'refresh-properties':
            this.getProperties();
            break;
          case 'refresh-alerts':
            this.getAlerts();
            break;
          case 'refresh-rentals':
            this.getRentals();
            break;
          case 'refresh-prop-list':
            this.getPropList();
            break;
          case 'refresh-payments':
            this.getPayments();
            break;
          default:
            break;
        }
      }

      if (this.postSuccessPage) {
        this.$router.push(this.postSuccessPage);
        this.postSuccessPage = null;
      }

      this.successCall = null;
      this.alertSuccess = false;
    },

    setErrorMessage(msg) {
      if (typeof msg === 'string') {
        this.error = msg;
        this.externalLoad(false);
      }
      else {
        this.error = msg.message;
        this.postErrorCall = Array(msg.postCall);
        this.postErrorPage = msg.postPage;
        if (!msg.notExternal) {
          this.externalLoad(false);
        }
      }
    },

    postErrorMessage() {
      for (const call of this.postErrorCall ? this.postErrorCall : []) {
        switch (call) {
          case 'refresh-userinfo':
            this.getUserInfo();
            break;
          case 'refresh-properties':
            this.getProperties();
            break;
          case 'refresh-alerts':
            this.getAlerts();
            break;
          case 'refresh-rentals':
            this.getRentals();
            break;
          case 'refresh-prop-list':
            this.getPropList();
            break;
          case 'refresh-payments':
            this.getPayments();
            break;
          default:
            break;
        }
      }

      if (this.postErrorPage) {
        this.$router.push(this.postErrorPage);
        this.postErrorPage = null;
      }

      this.postErrorCall = null;
      this.alertError = false;
    },

    setInfoMessage(msg) {
      if (typeof msg === 'string') {
        this.info = msg;
        this.externalLoad(false);
      }
      else {
        this.info = msg.message;
        this.postInfoCall = Array(msg.postCall);
        this.postInfoPage = msg.postPage;
        if (!msg.notExternal) {
          this.externalLoad(false);
        }
      }
    },

    postInfoMessage() {
      for (const call of this.postInfoCall ? this.postInfoCall : []) {
        switch (call) {
          case 'refresh-userinfo':
            this.getUserInfo();
            break;
          case 'refresh-properties':
            this.getProperties();
            break;
          case 'refresh-alerts':
            this.getAlerts();
            break;
          case 'refresh-rentals':
            this.getRentals();
            break;
          case 'refresh-prop-list':
            this.getPropList();
            break;
          case 'refresh-payments':
            this.getPayments();
            break;
          default:
            break;
        }
      }

      if (this.postInfoPage) {
        this.$router.push(this.postInfoPage);
        this.postInfoPage = null;
      }

      this.postInfoCall = null;
      this.alertInfo = false;
    },

    resetLoading() {
      this.loading = false;
      this.externalLoads = 0;
      this.fetches = [];
    },

    externalLoad(load) {
      if (load) {
        this.loading = load;
        this.externalLoads++;
      }
      else {
        this.loading = false;
        this.externalLoads = 0;
      }
    },

    addFetches(add) {
      this.lastDateLoad = new Date().getTime();
      if (this.fetches.length === 0) {
        this.fetches.push(add);
        this.loading = add;
      }
      else {
        this.fetches.push(add);
      }
    },

    removeFetches(remove) {
      const idx = this.fetches.indexOf(remove);
      if (idx >= 0) {
        this.fetches.splice(idx, 1);
        if (this.fetches.length > 0) {
          this.loading = this.fetches[0];
        }
        else if (this.fetches.length === 0 && this.externalLoads === 0) {
          this.loading = false;
        }
      }
    },

    updateAll() {
      this.getUserInfo();
      this.getProperties();
      this.getAlerts();
      this.getRentals();
      this.getPropList();
      this.getPayments();
    },

    getUserInfo() {
      this.addFetches('User information');
      fetchV2('GET /users/personal').then(data => {
        for (const key of Object.keys(data.settings)) {
          data.settings[key] = data.settings[key] == 0 ? false : true;
        }
        this.userInfo = data;
        this.removeFetches('User information');
      }).catch(err => {
        console.error(err);
        this.removeFetches('User information');
        this.$router.replace('/');
      })
    },

    getProperties() {
      this.addFetches('Property information');
      fetchV2('GET /properties/personal').then(data => {
        this.properties = data;
        this.removeFetches('Property information');
      }).catch(err => {
        console.error(err);
        this.removeFetches('Property information');
        this.$router.replace('/');
      })
    },

    getAlerts() {
      this.addFetches('Alerts');

      fetchV2('GET /alerts/personal').then(data => {
        this.alerts = data;
        this.removeFetches('Alerts');
      }).catch(err => {
        console.error(err);
        this.removeFetches('Alerts');
        this.$router.replace('/');
      })
    },

    getRentals() {
      this.addFetches('Rental information');
      fetchV2('GET /rentals/personal').then(data => {
        this.rentals = data.sort((a, b) => a.check_in < b.check_in ? 1 : a.check_in > b.check_in ? -1 : 0);
        this.removeFetches('Rental information');
      }).catch((err) => {
        console.error(err);
        this.removeFetches('Rental information');
        this.error = 'An error has occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.';
      })
    },

    getPropList() {
      this.addFetches('Properties list');
      fetchV2('GET /properties/personal/list').then(data => {
        this.propList = data;
        this.removeFetches('Properties list');
      }).catch((err) => {
        console.error(err);
        this.removeFetches('Properties list');
        this.error = 'An error has occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.';
      })
    },

    getPayments() {
      this.addFetches('Payment information');
      fetchV2('GET /users/payments').then((data) => {
        this.payments = data;
        this.removeFetches('Payment information');
      }).catch((err) => {
        console.error(err);
        this.removeFetches('Payment information');
        this.error = 'An error has occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.';
      })
    },

    lsTest() {
      let test = 'test';
      try {
        sessionStorage.setItem(test, test);
        sessionStorage.removeItem(test);
        return true;
      } catch(e) {
        console.error('ERROR: Local Storage is not supported in this browser.');
        return false;
      }
    },

    updatePermissions(pms) {
      this.permissions = pms;
      if (this.lsTest()) {
        sessionStorage.setItem('permissions', pms);
      }
      if (this.permissions == 2)
        this.$router.push('/dashboard/manager/my-account');
      else if (this.permissions == 4)
        this.$router.push('/dashboard/member/my-account');
      else if (this.permissions == 8)
        this.$router.push('/dashboard/admin/settings');
    },

    getRoute(route) {
      if (route === 'table_management')
        return 'admin';
      return route;
    },

    logout() {
      fetchV2('POST /users/logout').then((data) => {
        if (this.lsTest()) {
          sessionStorage.clear();
        }
        this.$router.push('/');
      }).catch((err) => {
        console.error(err);
        this.$router.push('/');
      })
    },

    onresize() {
      if (document.getElementById('AppBar') && document.getElementById('MainTitle')) {
        if (document.getElementById('MainTitle').offsetLeft + document.getElementById('MainTitle').offsetWidth > document.getElementById('AppBar').offsetWidth - 20) {
          this.longTitleWidth = document.getElementById('MainTitle').offsetWidth;
          this.env.appName = process.env.VUE_APP_SHORT_TITLE;
        }
        else if (document.getElementById('MainTitle').offsetLeft + this.longTitleWidth < document.getElementById('AppBar').offsetWidth - 20) {
          this.env.appName = process.env.VUE_APP_LONG_TITLE;
        }
      }
    },

    generateCustomCSS() {
      const imports = process.env.VUE_APP_FONTS;
      let strImport = '';
      for (const imp of imports.split(',')) {
        const fontName = imp.match(/[\w-]+\.(ttf|otf)$/)[0].split('\.')[0];
        if (fontName.split('-').length === 1) {
          strImport += '@font-face { font-family: "' + fontName + '"; src: url("/fonts/' + imp + '"); }';
        }
        else {
          strImport += '@font-face { font-family: "' + fontName.split('-')[0] + '"; src: url("/fonts/' + imp + '"); font-weight: ' + fontName.split('-')[1] + ';}';
        }
      }

      const style = document.createElement('style');
      style.innerHTML =
      strImport +

      ':root, html, body, .v-application { ' +
        '--primary: #' + process.env.VUE_APP_COLOR_PRIMARY + ';' +
        '--secondary: #' + process.env.VUE_APP_COLOR_SECONDARY + ';' +
        '--accent: #' + process.env.VUE_APP_COLOR_ACCENT + ';' +

        '--error: #' + process.env.VUE_APP_COLOR_ERROR + ';' +
        '--info: #' + process.env.VUE_APP_COLOR_INFO + ';' +
        '--success: #' + process.env.VUE_APP_COLOR_SUCCESS + ';' +
        '--warning: #' + process.env.VUE_APP_COLOR_WARNING + ';' +
        '--gray: #' + process.env.VUE_APP_COLOR_GRAY + ';' +

        '--title-font:' + process.env.VUE_APP_FONT_TITLE + ';' +
        '--title-color: #' + process.env.VUE_APP_COLOR_TITLE + ';' +
        '--title-voffset: ' + process.env.VUE_APP_VOFFSET_TITLE + ';' +
        '--title-fontweight: ' + process.env.VUE_APP_FONTWEIGHT_TITLE + ';' +
        '--title-spacing: ' + process.env.VUE_APP_SPACING_TITLE + ';' +

        '--heading-font:' + process.env.VUE_APP_FONT_HEADING + ';' +
        '--heading-color: #' + process.env.VUE_APP_COLOR_HEADING + ';' +
        '--heading-voffset: ' + process.env.VUE_APP_VOFFSET_HEADING + ';' +
        '--heading-fontweight: ' + process.env.VUE_APP_FONTWEIGHT_HEADING + ';' +
        '--heading-spacing: ' + process.env.VUE_APP_SPACING_HEADING + ';' +

        '--body-font:' + process.env.VUE_APP_FONT_BODY + ';' +
        'font-family: ' + process.env.VUE_APP_FONT_BODY + ';' +
      '}' +


      '.v-container {' +
        'font-family: \'' + process.env.VUE_APP_FONT_BODY + '\';' +
      '}' +

      `div.logo {` +
        `border-radius: 100%;` +
        `overflow: hidden;` +
        `height: ${process.env.VUE_APP_LOGO_SIZE};` +
        `width: ${process.env.VUE_APP_LOGO_SIZE};` +
        `background: url(${process.env.VUE_APP_RESOURCE_BASE_URL}/logo.png) no-repeat center center;` +
        `background-size: contain;` +
      `}` +

      `h1 {` +
        `font-family: var(--title-font) !important;` +
        `color: var(--title-color);` +
        `margin-top: var(--title-voffset);` +
        `padding-bottom: calc(var(--title-voffset) * -1);` +
        `font-weight: var(--title-fontweight);` +
        `letter-spacing: var(--title-spacing);` +
        `text-align: center;` +
      `}` +

      `h2 {` +
        `font-family: var(--heading-font) !important;` +
        `color: var(--heading-color);` +
        `margin-top: var(--heading-voffset);` +
        `font-weight: var(--heading-fontweight);` +
        `font-size: 32px;` +
        `letter-spacing: var(--heading-spacing);` +
        `text-align: center;` +
      `}` +

      'h3, h4, h5, h6 {' +
        'font-family: var(--heading-font) !important;' +
        'color: var(--heading-color);' +
        'margin-top: var(--heading-voffset);' +
        'font-weight: var(--heading-fontweight);' +
        'letter-spacing: var(--heading-spacing);' +
        `text-align: center;` +
      '}';


      document.head.appendChild(style);
    },

    loadEnvironment() {
      this.env = {
        rentalsActive: /^true$/i.test(process.env.VUE_APP_RENTALS_ACTIVE),

        shortName: process.env.VUE_APP_SHORT_TITLE,
        longName: process.env.VUE_APP_LONG_TITLE,
        appName: process.env.VUE_APP_LONG_TITLE,
        clubName: process.env.VUE_APP_CLUB_NAME,

        colorTitle: process.env.VUE_APP_COLOR_TITLE,

        supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
        supportPhone: process.env.VUE_APP_SUPPORT_PHONE,
        emailGreetings: process.env.VUE_APP_EMAIL_GREETINGS.split(','),
        emailSignOffs: process.env.VUE_APP_EMAIL_SIGNOFFS.split(','),
        emailLists: process.env.VUE_APP_EMAIL_LIST_NAMES.split(',').map((item, i) => {
          return {
            text: item,
            value: process.env.VUE_APP_EMAIL_LIST_VALUES.split(',')[i],
          }
        }),

        colorPrimary: process.env.VUE_APP_COLOR_PRIMARY,
        colorSecondary: process.env.VUE_APP_COLOR_SECONDARY,
        colorAccent: process.env.VUE_APP_COLOR_ACCENT,
        colorError: process.env.VUE_APP_COLOR_ERROR,
        colorInfo: process.env.VUE_APP_COLOR_INFO,
        colorSuccess: process.env.VUE_APP_COLOR_SUCCESS,
        colorWarning: process.env.VUE_APP_COLOR_WARNING,
        colorGray: process.env.VUE_APP_COLOR_GRAY,

        fontTitle: process.env.VUE_APP_FONT_TITLE,
        colorTitle: process.env.VUE_APP_COLOR_TITLE,
        voffsetTitle: process.env.VUE_APP_VOFFSET_TITLE,
        fontWeightTitle: process.env.VUE_APP_FONTWEIGHT_TITLE,
        spacingTitle: process.env.VUE_APP_SPACING_TITLE,

        fontHeading: process.env.VUE_APP_FONT_HEADING,
        colorHeading: process.env.VUE_APP_COLOR_HEADING,
        voffsetHeading: process.env.VUE_APP_VOFFSET_HEADING,
        fontWeightHeading: process.env.VUE_APP_FONTWEIGHT_HEADING,
        spacingHeading: process.env.VUE_APP_SPACING_HEADING,

        fontBody: process.env.VUE_APP_FONT_BODY,

        logo: process.env.VUE_APP_RESOURCE_BASE_URL + '/logo.png',
      };
    },
  },

  destroyed() {
    window.removeEventListener('resize', this.onresize, false);
  },

  created() {
    window.addEventListener('resize', this.onresize, false);

    this.loadEnvironment();
    this.setPerms = setPerms;

    if (this.lsTest()) {
      if (sessionStorage.getItem('version') === this.version) {
        if (this.permissions === 0 && sessionStorage.getItem('permissions') != null)
          this.permissions = parseInt(sessionStorage.getItem('permissions'));
      }
      else {
        sessionStorage.clear();
      }
      sessionStorage.setItem('version', this.version);
    }

    this.generateCustomCSS();

    // Set menu items
    for (const submenu of Object.values(this.menu)) {
      for (const item of submenu) {
        if (item.rentalsActive) {
          item.display = this.env.rentalsActive;
        }
        else {
          item.display = true;
        }
      }
    }
  },

  mounted() {
    setTimeout(() => {
      this.onresize();
    }, 0)
  }
};
</script>

<style>
  .v-sheet, .v-card, .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active{
    border-radius: 0 !important;
  }

  .menu-section {
    color: #606060;
    display: block;
    padding: 8px 24px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .007px;
    text-transform: uppercase;
  }

  .loading-words {
    font-family: sans-serif !important;
    color: #FFFFFF;
  }

  .loading {
    z-index: -1;
    opacity: 0;
  }

  .fade-infront {
    animation: fadeInfront forwards 0.5s;
  }

  @keyframes fadeInfront {
    0% {
      z-index: -1;
      opacity: 0;
    }
    1% {
      z-index: 2147483647;
      opacity: 0.01;
    }
    100% {
      z-index: 2147483647;
      opacity: 1;
    }
  }
</style>
